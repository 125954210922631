import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

// Styles //
import styled from "styled-components";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

// store
import { Context } from "../../../contexts/Store";

import $ from "jquery";

// media
import completedIcon from "../../../assets/icons/session-completed-icon.svg";
import calendarIcon from "../../../assets/icons/session-calendar-icon.svg";
import clockIcon from "../../../assets/icons/session-clock-icon.svg";
import callIcon from "../../../assets/icons/session-call-icon.svg";
import meetIcon from "../../../assets/icons/session-meet-icon.svg";
import leftArrowIcon from "../../../assets/icons/leftArrowGrey.svg";
import emergency from "../../../assets/icons/emergency-call.svg";
import profile from "../../../assets/icons/profile-picture.svg";
import printIcon from "../../../assets/icons/print-icon.svg";
import downloadIcon from "../../../assets/icons/download-icon.svg";
import shareIcon from "../../../assets/icons/share-icon.svg";
import youtubePlayIcon from "../../../assets/icons/youtube-icon.svg";

// axios
import { PatientConfig } from "../../../axiosConfig";

// Lottie
import Lottie from "lottie-react";
import LoaderLottie from "../../../assets/lottie/LoaderLottie.json";
import BottomNavigation from "../../general/BottomNavigation";
import ShareReport from "../modals/ShareReport";

function SummaryReport() {
    const [sessionDetail, setSessionDetail] = useState("");
    const [DownloadLink, setDownloadLink] = useState("");
    const [summaryDetails, setSummaryDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [share, setShare] = useState(false);
    const [sessions, setSessions] = useState([]);
    const [selectedSession, setSelectedSession] = useState(1);
    const [selectedSessions, setSelectedSessions] = useState([]);

    const { state } = useContext(Context);

    let accessToken = state.user_details.access_token;

    const slug = useParams();

    const getSessionDetail = () => {
        setIsLoading(true);
        PatientConfig.get(
            `api/v1/cases/sessions/view-summary-report/${slug.id}`,
            {
                headers: {
                    Authorization: `Bearer  ${accessToken}`,
                },
            }
        )
            .then((response) => {
                setSessionDetail(response.data.data.data);
                setSummaryDetails(response.data.data.summary_reports);
                setIsLoading(false);
            })
            .catch((error) => {
                // console.error("Error fetching data:", error);
            });
    };

    useEffect(() => {
        getSessionDetail();
    }, []);

    useEffect(() => {
        const uniqueSessionNumbers = [];

        summaryDetails.forEach((item) => {
            if (!uniqueSessionNumbers.includes(item.current_session_number)) {
                uniqueSessionNumbers.push(item.current_session_number);
            }
        });

        // Update the sessions state with the array of unique session numbers
        setSessions(uniqueSessionNumbers);
        setSelectedSession(sessions[0]);
    }, [summaryDetails]);

    useEffect(() => {
        // Filter summaryDetails based on selectedSession
        const filteredSessions = summaryDetails.filter(
            (item) => item.current_session_number === selectedSession
        );

        // Update selectedSessionDetails state with filtered sessions
        setSelectedSessions(filteredSessions);
    }, [selectedSession, summaryDetails]);

    // const sessions = [
    //   {
    //     id: 1,
    //     session: "Session 1",
    //     isCompleted: true,
    //     status: "Completed",
    //   },
    //   {
    //     id: 2,
    //     session: "Session 2",
    //     isCompleted: true,
    //     status: "Completed",
    //   },
    //   {
    //     id: 3,
    //     session: "Session 3",
    //     isCompleted: false,
    //     status: "Cancelled",
    //   },
    //   {
    //     id: 4,
    //     session: "Session 4",
    //     isCompleted: false,
    //     status: "Rescheduled",
    //   },
    // ];
    // console.log(summaryDetails, 'summaryDetails')

    // const downloadPdf = () => {
    //   const pdfUrl =
    //     'https://api.mindmitra.talrop.works/media/patients/summary-reports/CRID-262023-00008.pdf'

    //   // Create a temporary <a> element to trigger the download
    //   const link = document.createElement('a')
    //   link.href = pdfUrl
    //   link.setAttribute('download', 'downloaded_file.pdf')
    //   document.body.appendChild(link)

    //   // Initiate the download
    //   link.click()

    //   // Clean up: remove the temporary <a> element
    //   link.parentNode.removeChild(link)
    // }
    const printPdf = () => {
        const pdfUrl =
            "https://api.mindmitra.talrop.works/media/patients/summary-reports/CRID-262023-00008.pdf";

        // Open a new window or tab with the PDF file
        const pdfWindow = window.open(pdfUrl);

        // Check if the window is opened successfully
        if (pdfWindow) {
            // After loading the PDF file, call the print() method
            pdfWindow.onload = () => {
                pdfWindow.print();
            };
        } else {
            // Display a message if the new window couldn't be opened
            // console.error(
            //     "The PDF file could not be printed. Please check your browser settings."
            // );
        }
    };
    useEffect(() => {
        if (share) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [share]);

    return (
        <>
            <MainContainer>
                <SubContainer>
                    {isLoading ? (
                        <Lottie
                            animationData={LoaderLottie}
                            style={{
                                width: 150,
                                margin: "auto",
                            }}
                        />
                    ) : (
                        <>
                            <BackButtonContainer to="/">
                                <LeftArrowContainer>
                                    <LeftArrow src={leftArrowIcon} alt="Icon" />
                                </LeftArrowContainer>
                                <BackButton>Back to Dashboard</BackButton>
                            </BackButtonContainer>

                            <MainBox>
                                <WhiteBox>
                                    <FirstRow>
                                        <FirstRowLeft>
                                            <ProfilePic
                                                src={
                                                    sessionDetail?.session_record_status?.toLowerCase() ==
                                                    "pending"
                                                        ? profile
                                                        : sessionDetail.expert_pic
                                                        ? sessionDetail.expert_pic
                                                        : profile
                                                }
                                                alt="icon"
                                            />
                                            <FirstMiddleDiv>
                                                <NameLine>
                                                    <NameText>
                                                        {sessionDetail?.session_record_status?.toLowerCase() ==
                                                        "pending"
                                                            ? "Awaiting Expert"
                                                            : sessionDetail.expert_name}
                                                    </NameText>
                                                    <SessionChip>
                                                        {
                                                            sessionDetail.current_session_number
                                                        }{" "}
                                                        of{" "}
                                                        {
                                                            sessionDetail.total_sessions
                                                        }{" "}
                                                        Session
                                                    </SessionChip>
                                                    {sessionDetail.is_emergency ? (
                                                        <EmergencyContainer>
                                                            <EmergencyIconContainer>
                                                                <EmergencyIcon
                                                                    src={
                                                                        emergency
                                                                    }
                                                                    alt="Icon"
                                                                />
                                                            </EmergencyIconContainer>
                                                            <EmergencyText>
                                                                Emergency
                                                            </EmergencyText>
                                                        </EmergencyContainer>
                                                    ) : (
                                                        ""
                                                    )}
                                                </NameLine>
                                                <SpecializationText>
                                                    {
                                                        sessionDetail.specialization
                                                    }
                                                </SpecializationText>
                                            </FirstMiddleDiv>
                                        </FirstRowLeft>
                                    </FirstRow>

                                    <SecondRow>
                                        <SubTitle>
                                            {sessionDetail.concern}
                                        </SubTitle>
                                        <SecondRowPara>
                                            {sessionDetail.concern_describe}
                                        </SecondRowPara>
                                        <CaseIdText>
                                            Case ID : #
                                            {sessionDetail.session_record_id}
                                        </CaseIdText>
                                        <br />
                                        {sessionDetail.describe ? (
                                            <>
                                                <DescriptionTitle>
                                                    Description
                                                </DescriptionTitle>
                                                <Description>
                                                    {sessionDetail.describe}
                                                </Description>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </SecondRow>
                                    <ThirdRow>
                                        <ThirdRowLeft>
                                            <SessionCount>
                                                Session{" "}
                                                {
                                                    sessionDetail.current_session_number
                                                }
                                            </SessionCount>
                                        </ThirdRowLeft>
                                        <ThirdRowRight>
                                            <StatusChip>
                                                <ChipIcon
                                                    src={completedIcon}
                                                    alt="icon"
                                                />
                                                <StatusChipText>
                                                    {
                                                        sessionDetail.session_record_status
                                                    }
                                                </StatusChipText>
                                            </StatusChip>
                                            <GreyChip>
                                                <ChipIcon
                                                    src={calendarIcon}
                                                    alt="icon"
                                                />
                                                <ChipText>
                                                    {sessionDetail.case_date}
                                                </ChipText>
                                            </GreyChip>
                                            <GreyChip>
                                                <ChipIcon
                                                    src={clockIcon}
                                                    alt="icon"
                                                />
                                                <ChipText>
                                                    {sessionDetail.start_time} -{" "}
                                                    {sessionDetail.end_time}
                                                </ChipText>
                                            </GreyChip>
                                            <GreyChip className="green_bg">
                                                <ChipIcon
                                                    src={
                                                        sessionDetail.meet_mode ==
                                                        "Video"
                                                            ? meetIcon
                                                            : callIcon
                                                    }
                                                    alt="icon"
                                                />
                                            </GreyChip>
                                        </ThirdRowRight>
                                    </ThirdRow>
                                </WhiteBox>
                            </MainBox>

                            <BottomContainer>
                                <BottomHeading>Summary Report</BottomHeading>
                                <MiddleContainer>
                                    <MiddleRightContainer>
                                        <TabContainer>
                                            {sessions?.map((session, index) => (
                                                <CompletedSessionChip
                                                    key={index}
                                                    className={
                                                        selectedSession ===
                                                        session
                                                            ? "active"
                                                            : ""
                                                    }
                                                    onClick={() => {
                                                        // setItem(item.id);
                                                        setSelectedSession(
                                                            session
                                                        );
                                                    }}
                                                >
                                                    <TextCompleted>
                                                        {session}
                                                    </TextCompleted>
                                                </CompletedSessionChip>
                                            ))}
                                        </TabContainer>
                                    </MiddleRightContainer>
                                    <MiddleLeftContainer>
                                        <ButtonsContainer>
                                            {/* <Button onClick={printPdf}>
                                                <ButtonIcon
                                                    src={printIcon}
                                                    alt="Icon"
                                                />
                                            </Button> */}
                                            <Button
                                                // onClick={downloadPdf}
                                                href={DownloadLink}
                                                download
                                                target="blank"
                                            >
                                                <ButtonIcon
                                                    src={downloadIcon}
                                                    alt="Icon"
                                                />
                                            </Button>
                                            {/* <Button
                                                onClick={() => {
                                                    // setItem(item.id);
                                                    setShare(true);
                                                }}
                                                // href={DownloadLink} target="blank"
                                            >
                                                <ButtonIcon
                                                    src={shareIcon}
                                                    alt="Icon"
                                                />
                                            </Button> */}
                                        </ButtonsContainer>
                                    </MiddleLeftContainer>
                                </MiddleContainer>

                                {selectedSessions?.map((summary, index) => (
                                    <>
                                        <SummaryReportContainer
                                            onLoad={() => {
                                                setDownloadLink(summary.file);
                                                // console.log(
                                                //     DownloadLink,
                                                //     "Download"
                                                // );
                                            }}
                                            key={index}
                                        >
                                            <LeftContainer>
                                                <LeftSubContainer>
                                                    <SessionDetailTopContainer>
                                                        <ExpertImageContainer>
                                                            <ExpertImage
                                                                src={profile}
                                                                alt="Profile Icon"
                                                            />
                                                        </ExpertImageContainer>
                                                        <ConcernAndExpert>
                                                            <Concern>
                                                                {
                                                                    summary.concern
                                                                }
                                                            </Concern>
                                                            <ExpertName>
                                                                {
                                                                    summary.expert_name
                                                                }
                                                            </ExpertName>
                                                        </ConcernAndExpert>
                                                    </SessionDetailTopContainer>
                                                    <SessionDetailBottomContainer>
                                                        <StatusChip>
                                                            <ChipIcon
                                                                src={
                                                                    completedIcon
                                                                }
                                                                alt="icon"
                                                            />
                                                            <StatusChipText>
                                                                Completed on{" "}
                                                                {
                                                                    summary.session_completed_date
                                                                }
                                                            </StatusChipText>
                                                        </StatusChip>
                                                        <GreyChip className="green_bg">
                                                            <ChipIcon
                                                                src={
                                                                    sessionDetail.meet_mode ==
                                                                    "Video"
                                                                        ? meetIcon
                                                                        : callIcon
                                                                }
                                                                alt="icon"
                                                            />
                                                        </GreyChip>
                                                    </SessionDetailBottomContainer>
                                                </LeftSubContainer>
                                                <LeftSubContainer>
                                                    <ContainerHeading>
                                                        Patient Details
                                                    </ContainerHeading>
                                                    <PatientDetailsContainer>
                                                        <PatientDetail>
                                                            <Key>Name</Key>
                                                            <Character>
                                                                :
                                                            </Character>
                                                            <Value>
                                                                {
                                                                    summary.patient_name
                                                                }
                                                            </Value>
                                                        </PatientDetail>
                                                        <PatientDetail>
                                                            <Key>Age</Key>
                                                            <Character>
                                                                :
                                                            </Character>
                                                            <Value>
                                                                {
                                                                    summary.patient_age
                                                                }
                                                            </Value>
                                                        </PatientDetail>
                                                        <PatientDetail>
                                                            <Key>Gender</Key>
                                                            <Character>
                                                                :
                                                            </Character>
                                                            <Value>
                                                                {
                                                                    summary.patient_gender
                                                                }
                                                            </Value>
                                                        </PatientDetail>
                                                        <PatientDetail>
                                                            <Key>
                                                                Date of Birth
                                                            </Key>
                                                            <Character>
                                                                :
                                                            </Character>
                                                            <Value>
                                                                {
                                                                    summary.patient_DOB
                                                                }
                                                            </Value>
                                                        </PatientDetail>
                                                    </PatientDetailsContainer>
                                                </LeftSubContainer>
                                                <LeftSubContainer>
                                                    <ContainerHeading>
                                                        Case Details
                                                    </ContainerHeading>
                                                    <PatientDetailsContainer>
                                                        <PatientDetail>
                                                            <Key>Case ID</Key>
                                                            <Character>
                                                                :
                                                            </Character>
                                                            <Value>
                                                                {
                                                                    summary.case_report_id
                                                                }
                                                            </Value>
                                                        </PatientDetail>
                                                        <PatientDetail>
                                                            <Key>
                                                                No of Sessions
                                                            </Key>
                                                            <Character>
                                                                :
                                                            </Character>
                                                            <Value>
                                                                {
                                                                    summary.no_of_sessions
                                                                }
                                                            </Value>
                                                        </PatientDetail>
                                                        <CaseDetailsContainer>
                                                            <PatientDetail>
                                                                <Key className="case">
                                                                    Expert Name
                                                                </Key>
                                                                <Character>
                                                                    :
                                                                </Character>
                                                                <Value>
                                                                    {
                                                                        summary.expert_name
                                                                    }
                                                                </Value>
                                                            </PatientDetail>
                                                            <PatientDetail>
                                                                <Key className="case">
                                                                    Date
                                                                </Key>
                                                                <Character>
                                                                    :
                                                                </Character>
                                                                <Value>
                                                                    {
                                                                        summary.session_completed_date
                                                                    }
                                                                </Value>
                                                            </PatientDetail>
                                                            <PatientDetail>
                                                                <Key className="case">
                                                                    Type
                                                                </Key>
                                                                <Character>
                                                                    :
                                                                </Character>
                                                                <Value>
                                                                    {
                                                                        summary.meet_mode
                                                                    }{" "}
                                                                    session
                                                                </Value>
                                                            </PatientDetail>
                                                            <PatientDetail>
                                                                <Key className="case">
                                                                    Follow-Up
                                                                    Needed
                                                                </Key>
                                                                <Character>
                                                                    :
                                                                </Character>
                                                                <Value>
                                                                    Yes
                                                                </Value>
                                                            </PatientDetail>
                                                        </CaseDetailsContainer>
                                                    </PatientDetailsContainer>
                                                </LeftSubContainer>
                                            </LeftContainer>
                                            <RightContainer>
                                                <SummaryReportContent>
                                                    <SummaryReportTitle>
                                                        Session Summary
                                                    </SummaryReportTitle>
                                                    <SummaryReportDescription>
                                                        {summary.summary}
                                                    </SummaryReportDescription>
                                                </SummaryReportContent>
                                                <SummaryReportContent>
                                                    <SummaryReportTitle>
                                                        Chief Complaints
                                                        Addressed
                                                    </SummaryReportTitle>
                                                    <SummaryReportDescription>
                                                        {
                                                            summary.chief_complaints
                                                        }
                                                    </SummaryReportDescription>
                                                </SummaryReportContent>
                                                <SummaryReportContent>
                                                    <SummaryReportTitle>
                                                        Diagnosis Insights
                                                    </SummaryReportTitle>
                                                    <SummaryReportDescription>
                                                        {summary.diagnosis}
                                                    </SummaryReportDescription>
                                                </SummaryReportContent>
                                                <SummaryReportContent>
                                                    <SummaryReportTitle>
                                                        Recommendations &
                                                        Interventions
                                                    </SummaryReportTitle>
                                                    <SummaryReportDescription>
                                                        {summary.recommendation}
                                                    </SummaryReportDescription>
                                                </SummaryReportContent>
                                                <SummaryReportContent>
                                                    <SummaryReportTitle>
                                                        Treatment Plan Forward
                                                    </SummaryReportTitle>
                                                    <SummaryReportDescription>
                                                        {summary.treatment_plan}
                                                    </SummaryReportDescription>
                                                </SummaryReportContent>
                                                <SummaryReportContent>
                                                    <SummaryReportTitle>
                                                        Suggestions for
                                                        Well-being
                                                    </SummaryReportTitle>
                                                    <SummaryReportDescription>
                                                        {summary.suggestions}
                                                    </SummaryReportDescription>
                                                </SummaryReportContent>
                                                <SummaryReportContent>
                                                    <SummaryReportTitle>
                                                        Recommended Videos
                                                    </SummaryReportTitle>
                                                    <RecommendedVideosContainer>
                                                        {summary.videos.map(
                                                            (video, index) => (
                                                                <RecommendedVideoContainer
                                                                    key={index}
                                                                >
                                                                    <ThumbnailContainer>
                                                                        <Thumbnail
                                                                            src={
                                                                                video?.image
                                                                            }
                                                                            alt="Thumbnail Image"
                                                                        />
                                                                        <a
                                                                            href={
                                                                                video.link
                                                                            }
                                                                            target="blank"
                                                                        >
                                                                            <PlayIcon
                                                                                src={
                                                                                    youtubePlayIcon
                                                                                }
                                                                                alt="Youtube Play Icon"
                                                                            />
                                                                        </a>
                                                                    </ThumbnailContainer>
                                                                    <a
                                                                        href={
                                                                            video.link
                                                                        }
                                                                        target="blank"
                                                                    >
                                                                        <VideoTitle>
                                                                            {
                                                                                video.title
                                                                            }
                                                                        </VideoTitle>
                                                                    </a>
                                                                </RecommendedVideoContainer>
                                                            )
                                                        )}
                                                    </RecommendedVideosContainer>
                                                </SummaryReportContent>
                                            </RightContainer>
                                        </SummaryReportContainer>
                                    </>
                                ))}
                            </BottomContainer>
                        </>
                    )}
                </SubContainer>
                {share && (
                    <ShareReport
                        DownloadLink={DownloadLink}
                        deleteModal={share}
                        onClose={() => setShare("")}
                    />
                )}
            </MainContainer>
            <BottomNavigation />
        </>
    );
}

export default SummaryReport;

const MainContainer = styled.div`
    width: 100%;
    background: ${THEME_COLORS.light_200};
    padding: 29px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    scrollbar-width: none;
    /* position: relative; */
    @media all and (max-width: 768px) {
        width: 100%;
        overflow-y: auto;
        padding: 50px 0px 150px 0;
    }
    @media all and (max-width: 480px) {
        padding-bottom: 90px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;
const SubContainer = styled.div`
    width: 800px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    @media all and (max-width: 1120px) {
        width: 750px;
    }
    @media all and (max-width: 1080px) {
        width: 90%;
    }
    @media all and (max-width: 768px) {
        padding-bottom: 30px;
    }
`;
const BackButtonContainer = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    background: #fff;
    padding: 6px 18px;
    border: 2px solid #e2e2e2;
    border-radius: 20px;
    cursor: pointer;
`;
const LeftArrowContainer = styled.div`
    width: 7px;
`;
const LeftArrow = styled.img``;
const BackButton = styled.span`
    color: #333;
    text-align: center;
    font-family: "DM_sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;

const MainBox = styled.div`
    width: 100%;
    margin-bottom: 22px;
    border-radius: 10px;
    border: 1px solid #e2e2e2;
    color: ${THEME_COLORS.chips_blue_on_container_2};
    &.active {
        display: block;
    }
    @media all and (max-width: 1080px) {
        display: block;
    }
`;
const WhiteBox = styled.div`
    position: relative;
    padding: 24px;
    background: ${THEME_COLORS.white};
    box-shadow: ${THEME_COLORS.elevation_user_u2};
    border-radius: 10px;
`;
const FirstRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const ProfilePic = styled.img`
    width: 36px;
    height: 36px;
    display: block;
    object-fit: cover;
`;
const FirstRowLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;
const FirstMiddleDiv = styled.div`
    margin-left: 16px;
`;
const NameLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;

    @media all and (max-width: 480px) {
        flex-wrap: wrap;
    }
`;
const NameText = styled.h6`
    font-family: "DM_sans_medium";
    font-size: 16px;
    color: ${THEME_COLORS.secondary};
`;
const SessionChip = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
    background: ${THEME_COLORS.chips_blue_container};
    border-radius: 4px;
    height: 24px;
    box-sizing: border-box;
    color: ${THEME_COLORS.chips_blue_on_container_2};
    font-family: "DM_sans";
    font-size: 12px;
`;
const EmergencyContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 4px 8px 4px 3px;
    align-items: center;
    border-radius: 4px;
    background: #ffe6d7;
    gap: 8px;
`;
const EmergencyIconContainer = styled.div`
    width: 16px;
    height: 16px;
    padding: 2px;
`;
const EmergencyIcon = styled.img``;
const EmergencyText = styled.span`
    color: #f28b4d;
    font-family: "DM_sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const CaseIdText = styled.span`
    display: inline-block;
    font-family: "DM_sans";
    font-size: 12px;
    color: ${THEME_COLORS.text_para};
`;
const DescriptionTitle = styled.span`
    color: #3e3e53;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display: block;
    margin-top: 16px;
`;
const Description = styled.p`
    color: #3e3e53;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 15px;
`;
const SecondRow = styled.div`
    margin-top: 12px;
`;
const SubTitle = styled.h6`
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.text_title};
    font-size: 16px;
`;
const SecondRowPara = styled.span`
    display: inline-block;
    font-size: 14px;
    font-family: "DM_sans";
    color: ${THEME_COLORS.text_para};
    margin-top: 2px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2; /* Change the number of lines as needed */
    text-overflow: ellipsis;
`;

const SpecializationText = styled.span`
    display: inline-block;
    font-family: "DM_sans";
    font-size: 13px;
    color: ${THEME_COLORS.text_para};
`;
const ThirdRow = styled.div`
    border: 1px solid #e2e2e2;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    margin-top: 12px;
    @media all and (max-width: 640px) {
        justify-content: space-between;
    }

    @media all and (max-width: 880px) {
        flex-direction: column;
        align-items: flex-start;
    }
    @media all and (max-width: 768px) {
        flex-direction: row;
        align-items: center;
    }

    @media all and (max-width: 610px) {
        flex-direction: column;
        align-items: flex-start;
    }

    @media all and (max-width: 540px) {
        flex-direction: row;
        align-items: center;
    }
    @media all and (max-width: 480px) {
        flex-wrap: wrap;
    }
`;
const ThirdRowLeft = styled.div`
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const SessionCount = styled.h5`
    color: #3e3e53;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.02cm;
    @media all and (max-width: 640px) {
        font-size: 13px;
    }
`;
const ThirdRowRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    @media all and (max-width: 540px) {
        flex-wrap: wrap;
        justify-content: center;
        width: 80%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
        justify-content: flex-start;
    }
`;
const StatusChip = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
    gap: 6px;
    height: 24px;
    box-sizing: border-box;
    background: ${THEME_COLORS.chips_blue_container};
    border-radius: 4px;
`;
const GreyChip = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
    gap: 6px;
    background: ${THEME_COLORS.light_200};
    border-radius: 4px;
    height: 24px;
    box-sizing: border-box;
    &.green_bg {
        background: #e0f8f2;
    }
`;
const ChipIcon = styled.img`
    width: auto;
`;
const StatusChipText = styled.span`
    display: inline-block;
    font-family: "DM_sans";
    font-size: 12px;
    text-transform: capitalize;
    color: ${THEME_COLORS.chips_blue_on_container_2};
`;
const ChipText = styled.span`
    display: inline-block;
    font-family: "DM_sans";
    font-size: 12px;
    color: ${THEME_COLORS.dark_300};
`;

const BottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 16px;
`;
const BottomHeading = styled.div`
    color: #3e3e53;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;
const MiddleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    position: relative;
    @media all and (max-width: 480px) {
        flex-wrap: wrap;
        gap: 20px;
    }
`;
const MiddleRightContainer = styled.div``;
const CompletedSessionChip = styled.div`
    border: 2px solid ${THEME_COLORS.light_300};
    border-radius: 53px;
    text-align: center;
    padding: 6px 16px;
    background-color: ${THEME_COLORS.light_100};
    margin-right: 10px;
    &.active {
        border: 2px solid ${THEME_COLORS.chips_blue_on_container_2};
        background: #fff;
    }
`;
const TextCompleted = styled.h6`
    font-size: 14px;
    color: ${THEME_COLORS.text_title};
    line-height: 20px;
    font-weight: 700;
`;
const SessionChipDisabled = styled.div`
    border: 1px solid ${THEME_COLORS.light_300};
    border-radius: 53px;
    text-align: center;
    padding: 8px 16px;
    margin-right: 10px;
    &.active {
        border: 2px solid ${THEME_COLORS.chips_blue_on_container_2};
        border-radius: 53px;
        text-align: center;
        padding: 8px 16px;
    }
`;
const Text = styled.h6`
    font-size: 14px;
    color: ${THEME_COLORS.text_para};
    line-height: 20px;
    font-weight: 700;
    &.active {
        color: ${THEME_COLORS.text_title};
    }
`;
const TabContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;
`;
const Tab = styled.span`
    display: flex;
    padding: 6px 16px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-radius: 53px;
    border: 2px solid #e2e2e2;
    background: #fff;
    color: #3e3e53;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    &.active {
        border: 2px solid #366ee3;
    }
`;
const MiddleLeftContainer = styled.div``;
const ButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
`;
const Button = styled.a`
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 32px;
    background: #fff;
`;
const ButtonIcon = styled.img`
    width: 20px;
    height: 20px;
`;
const SummaryReportContainer = styled.div`
    display: flex;
    padding: 24px;
    align-items: flex-start;
    gap: 20px;
    justify-content: space-between;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid #e2e2e2;
    background: #fff;
    box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.04);
    @media all and (max-width: 560px) {
        flex-direction: column;
    }
`;

const LeftContainer = styled.div`
    display: flex;
    width: 36%;
    min-width: 255px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    @media all and (max-width: 1050px) {
        /* width: 38%; */
    }
`;
const LeftSubContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    padding: 10px;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #e2e2e2;
    box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.03);
`;
const SessionDetailTopContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;
    align-self: stretch;
`;
const ExpertImageContainer = styled.div`
    width: 46px;
    height: 46px;
    border-radius: 46px;
`;
const ExpertImage = styled.img`
    border-radius: 46px;
`;
const ConcernAndExpert = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    flex: 1 0 0;
`;
const Concern = styled.span`
    color: #3e3e53;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;
const ExpertName = styled.div`
    color: #2b3990;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
`;
const SessionDetailBottomContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;
`;
const ContainerHeading = styled.span`
    color: #3e3e53;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;
const PatientDetailsContainer = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
`;
const PatientDetail = styled.li`
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
`;
const Key = styled.span`
    color: #818181;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    width: 86px;
    &.case {
        width: 79px;
    }
`;
const Character = styled.span`
    color: #818181;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
`;
const Value = styled.span`
    color: #3e3e53;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    max-width: 104px;
`;
const CaseDetailsContainer = styled.div`
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    background: #f4f4f4;
`;
const RightContainer = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 60%;
    @media all and (max-width: 560px) {
        width: 100%;
    }
`;
const SummaryReportContent = styled.li`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
`;
const SummaryReportTitle = styled.h6`
    color: #aea8a8;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 10px;
`;
const SummaryReportDescription = styled.p`
    color: #3e3e53;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;
const RecommendedVideosContainer = styled.ul`
    display: flex;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    flex-wrap: wrap;
    width: 100%;
    @media all and (max-width: 980px) {
        flex-direction: column;
    }
    @media all and (max-width: 560px) {
        flex-direction: row;
    }
`;
const RecommendedVideoContainer = styled.li`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    width: 31%;
    @media all and (max-width: 980px) {
        width: 100%;
    }
    @media all and (max-width: 560px) {
        width: 31%;
    }
`;
const ThumbnailContainer = styled.div`
    width: 100%;

    /* width: 144px; */

    flex-shrink: 0;
    position: relative;
`;
const Thumbnail = styled.img`
    border-radius: 8px;
`;
const PlayIcon = styled.img`
    width: 24px;
    height: 16.872px;
    flex-shrink: 0;
    position: absolute;
    top: 42%;
    right: 42%;
    @media all and (max-width: 980px) {
        width: 50px;
        height: auto;
        top: 44%;
        right: 44%;
    }
    @media all and (max-width: 560px) {
        width: 24px;
        height: 16.872px;
        top: 42%;
        right: 42%;
    }
`;
const VideoTitle = styled.span`
    color: #3e3e53;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    &:hover {
        text-decoration: underline;
        color: #366ee3;
    }
`;
